html,
body {
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font: 300 1em "SF Pro Display", "SF Pro Icons", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
}

a {
  text-decoration: none;
  color: #5b5be9;
}

a:hover {
  text-decoration: underline;
  color: #000000;
}

hr {
  padding: 0;
  margin: 0;
  border-top: 1px solid #cccccc;
  border-bottom: none;
}

header {
  padding: 20px;
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
  margin-top: 20px;
}

header .logo {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  background-image: url("../public/assets/images/AppIcon-1024px-light-100.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 15px;
}

main {
  padding: 20px;
  margin: 0 auto;
  max-width: 1000px;
}
.listing-detail {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #cccccc;
}

.listing-detail img {
  cursor: pointer;
}

.listing-detail .details {
  padding: 20px;
}

.listing-detail .details h1 {
  margin: 0;
  padding: 0;
}

footer {
  display: flex;
  padding: 20px;
  margin: 0 auto 50px;
  max-width: 480px;
  font-size: 0.9em;
  color: #222222;
}

footer span {
  flex-grow: 1;
}

footer .links {
  text-align: right;
}

footer .links a {
  display: inline-block;
  margin-left: 20px;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #999999;
    background-color: #191919;
  }

  a {
    color: #aaf;
  }

  a:hover {
    color: #ffffff;
  }

  hr {
    border-top: 1px solid #222222;
  }

  .listing-detail {
    border: 1px solid #222222;
  }

  .listing-detail .details h1 {
    color: #e0e0e0;
  }

  .listing-detail .details .price {
    color: #999999;
  }

  .listing-detail .details .description {
    color: #e0e0e0;
  }

  footer {
    color: #999999;
  }
}

/*__________________________________________---*/
.contentSec {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}
.logoImageSec > a > img {
  text-align: center;
  /* height: 100px; */
}
.logo_icon {
  width: 100%;
  height: 100%;
}
.secureSec {
  margin-top: 5px;
}
.secureSec > p {
  line-height: 24px;
}
.privactSec > h1 {
  margin-bottom: 1px;
}
.privactSec .sec_title {
  margin-top: 0;
  margin-bottom: 0;
}
.privactSec > h4 {
  margin-top: 5px;
  font-size: 22px;
}
.privactSec > p {
  font-size: small;
  word-spacing: 1px;
}
.privactSec {
  margin-top: 40px;
}
.apple_icons_sec {
  margin-top: 60px;
}
.apple_icons_sec > a > img {
  width: 300px;
}
.QRcodeSec {
  margin-top: 50px;
}
.bottomparagraph {
  margin-top: 20px;
  padding: 20px;
  line-height: 24px;
}

.loader {
  border: 5px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 48%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
